.content {
  width: 100%;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #1a1a1a;
  min-height: 100vh;
}

.particles-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content-container {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1920px;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0 20px;
}

.content-left {
  flex: 1;
  padding-right: 20px;
}

.content-left h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.content-left p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 2rem;
}

.cta-button {
  padding: 12px 24px;
  font-size: 1.1rem;
  background-color: #ffb902;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #fc0100;
}

.content-right {
  flex: 1;
  max-width: 960px;
}

.image-container {
  width: 100%;
  line-height: 0;
  max-width: 960px;
  margin: 0 auto;
}

.content img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.animated-title {
  font-size: 2.5em;
  color: #ffffff;
  margin-bottom: 20px;
  position: relative;
  animation: fadeInUp 1s ease-out, glow 2s ease-in-out infinite;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.5),
                 0 0 30px rgba(255, 255, 255, 0.3);
  }
  100% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }
}

.animated-title::before {
  content: "Welcome to ICG GAMING";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffb902;
  border-right: 2px solid #fc0100;
  overflow: hidden;
  animation: typing 4s steps(30) infinite;
}

@keyframes typing {
  0%, 90%, 100% {
    width: 0;
  }
  30%, 60% {
    width: 100%;
  }
}

@media screen and (max-width: 968px) {
  .content-container {
    flex-direction: column;
    text-align: center;
  }

  .content-left {
    padding-right: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  .content {
    padding: 20px 10px;
  }

  .content-left h2 {
    font-size: 2rem;
  }
}
